@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
header {
   display: block;
   min-height: 70px;
   background-color: #fff;
   padding: 0;
   position: fixed;
   top: 0;
   right: 0;
   left: 220px;
   border-bottom: 1px solid #e5e5e5;
   z-index: 3;
}

.titleSection {
   margin:10px 0 0 20px;
   display:inline-block;
}

.HeaderTitle {
   font-weight: 700;
   font-size:18px;
   color:#000;
   float:none;
}

.HeaderSubTitle {
   font-weight: 400;
   font-size:13px;
   color:#717274;
   margin-top:2px;
   float:none;
}

#quickAdd {
   box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
   display: inline-flex;
   font-weight: 500;
   height: 48px;
   width: 48px;
   border-radius: 24px;
   color: #3c4043;
   background-color: #fff;
   text-align: center;
   cursor: pointer;
   font-weight: 600;
   -webkit-transition: ease-in-out .3s;
   transition: ease-in-out .3s;
   margin: 12px 12px 0 10px;
   float:right;
}
#quickAdd:hover {
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
    background-color: #fafafb;
    text-decoration: none!important;
}

#quickAdd i {
   background-size: 20px;
   content: '';
   display: block;
   border-radius: 50%;
   height: 48px;
   width: 48px;
   text-align: center;
   line-height: 48px;
   font-size: 20px;
   color: #fb5a5f;
}


.actionsBar {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 10px 20px;
  position: fixed;
  top: 70px;
  width: 100%;
  height: 50px;
  line-height: 20px;
  z-index: 1;
  font-size: 14px;
  color:#666
}

@charset "utf-8";

.modalBackground {
   background:#fff;
   position:relative;
   width:100%;
   height:calc(100vh - 40px);
   z-index:999;
   color: #2c2d30;
   overflow: scroll;
   padding-bottom:40px;

}

.modalBackground button.modalClose {
   width:60px;
   height:60px;
   position: fixed;
   top:50px;
   right:50px;
   background: url(/static/media/close.d49c1a6e.png) center no-repeat;
   border-radius: 30px;
   border:none;
   -webkit-transition: all ease-in-out 500ms;
   transition: all ease-in-out 500ms;
   outline: none!important;
   box-shadow: none!important;

}
.modalBackground button.modalClose:hover {
   background: url(/static/media/close.d49c1a6e.png) center no-repeat #e8e8e8;

}
.modalBackground .modalContent {
   width:640px;
   height:auto;
   position: relative;
   margin: 60px auto 0;
}

.modalBackground  h1 {
   font-size: 34px;
   font-weight: 900;
   height:34px;
   padding:0;
   margin:0;
}

.modalBackground .subHeading{
   color:#717274;
   font-size: 15px;
   font-weight: 300;
   margin:25px 0 55px;
}

.modalBackground .form-group {
   margin-bottom:20px;
   position: relative;
   min-height: 106px;
}

.modalBackground .btn-toolbar {
   padding-top:20px;
}

.modalBackground .form-group .overlayTitle {
  height:auto;
  min-height:106px;
  position:absolute;
  top: 0;
  left: -15px;
  right: -15px;
  border-radius:10px;
  overflow:hidden;
  background: repeating-linear-gradient(
    315deg,
    #ececec,
    #ececec 2px,
    #fff 2px,
    #fff 8px
  );
  z-index:99;
  cursor:pointer;
}

.modalBackground .form-group .overlayTitle .title {
  width:100%;
  position:absolute;
  top:38px;
  font-size: 18px;
  text-align: center;
  font-weight:600;
}
.modalBackground .form-group .overlayTitle .description {
  width:100%;
  position:absolute;
  top:66px;
  font-size: 16px;
  text-align: center;
  color: #696969;
  font-weight: 300;
}
.modalBackground .form-group .overlayTitle .step {
  width:30px;
  height:30px;
  line-height:30px;
  border-radius: 15px;
  left:50%;
  margin-left:-15px;
  background: #82d483;
  position:absolute;
  top:6px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.modalBackground label {
   font-size: 16px;
   font-weight:600;
}

.modalBackground .form-control {
   height:47px;
   outline: none!important;
   box-shadow: none;
   border: 1px solid #919193;
   font-size: 20px;
   color: #2c2d30;
   font-weight:400;
}

.modalBackground .form-group ::-webkit-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.modalBackground .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.modalBackground .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.modalBackground .form-group ::placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .helpText {
   font-size: 12px;
   height:12px;
   color:#717274;
   margin-top:8px;
   font-weight: 400;
}

.modalBackground .radioSelector {
   height:55px;
   width:100%;
   border: 1px solid #e8e8e8;
   background: #f9f9f9;
   border-radius: 5px;
   margin-bottom: 8px;
   overflow: hidden;
   position: relative;
   cursor: pointer;
}

.modalBackground .radioSelector.active, .modalBackground .radioSelector:hover {
   border: 1px solid #2d9ee0;
   background: #eaf5fc;
}

.modalBackground .radioSelector input[type=radio]{
   position: absolute;
   left:13px;
   top:13px;
}

.modalBackground .radioSelector label {
   margin: 0;
   width: 100%;
   position: relative;
   padding-left:36px;
   padding-top:8px;
   cursor: pointer;
   height: 55px;
}

.modalBackground .radioSelector label.noDescription {
   padding-top:15px;
}

.modalBackground .radioSelector label span {
   font-size: 16px;
   font-weight: 600;


}

.modalBackground .radioSelector .subLabel {
   font-size: 15px;
   color: #717274;
   font-weight: 300;
}

.modalBackground .conditionOperator {
   text-align: center;
   font-size:24px;
   font-weight:700;
}

.react-toggle-track {
  position:relative
}
.react-toggle-thumb {
  -webkit-transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px!important;
  left: 2px!important;
  width: 23px;
  height: 23px;
  border: none!important;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none!important
}
.react-toggle-track {
    width: 64px!important;
    height: 26px!important;
    background-color: #f26130!important;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.react-toggle--checked .react-toggle-track {
    background-color: #008952!important;
}
.react-toggle--checked .react-toggle-thumb {
    left: 40px!important;
}
.react-toggle {
  margin-right:15px;
}
.toggle-group label span {
  font-size: 16px!important;
  line-height: 20px;
  margin: 0;
  padding: 0;
  height: 20px;
  position: relative;
  top: -6px;
  font-weight: 400
}

.secondaryContent {
   position: fixed;
   width: 399px;
   height: calc(100vh - 120px);
   border-left: 1px solid #dfdfdf;
   float: left;
   top:120px;
   right:0;
   overflow: hidden;
   overflow-y: scroll;
}

.secondaryContent .heading {
   border-bottom: 1px solid #dfdfdf;
   background: #f9f9f9;
   padding: 19px 12px;
   position: absolute;
   width: 399px;
   height: 80px;
   top: 0;
   left: 0;
}

.secondaryContent .heading i {
   position: absolute;
   top:30px;
   right:20px;
   color:#6c6d6f;
   font-size:20px;
}

.secondaryContent .heading i:hover {
   cursor:pointer;
   color:#2d85b9;
}

.secondaryContent .heading .HeaderTitle {
  width: 330px;
  white-space: nowrap;
}
.secondaryContent .content {
   width:100%;
   height:auto;
   padding:15px;
   margin-top: 80px;
}

.secondaryContent .content .section {
   height: auto;
   padding-bottom: 7px;
   border-bottom: 1px solid #e8e8e8;
   margin-bottom: 20px
}

.secondaryContent .content .section h3 {
   padding:0;
   margin:0;
   font-size: 16px;
   line-height:16px;
   font-weight: 300;
   color:#333;
   margin-bottom:20px;
}

ul {
   list-style-type: none;
   padding-left:5px;
   font-size: 14px;
}

.secondaryContent .content .section label {
   font-size: 14px;
   line-height:14px;
   font-weight: 500;
   color: #2d85b9;
   margin-bottom:3px;
}

.secondaryContent .content .section .titleAns {
   font-size: 16px;
   line-height:16px;
   font-weight: 400;
   color:#5f5f5f;
   margin-bottom:10px;
}


@charset "utf-8";

.LeftSection{
   width: 220px;
   background: #141922;
   float: left;
   min-height: 100vh;
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
}

#teamName {
   font-size: 18px;
   line-height: 25px;
   padding: 0 1rem;
   color: #fff;
   position: relative;
   z-index: 50;
   flex-basis: 61px;
   flex-shrink: 0;
   font-weight:900;
   white-space:nowrap;
   width:180px;
   padding: 10px 10px 10px 15px;
   -webkit-transition: all 0s;
   transition: all 0s
}

#team_menu_user_name {
   color: #cac4c9;
   font-size: 14px;
   line-height: 14px;
   padding: 0 15px;
   position: relative;
   -webkit-transition: all 0s;
   transition: all 0s;
   top: -10px;
}

#team_menu_user_name i {
   font-size:14px;
   margin-right:5px;
   color:#3a938b;
}


.Tgmenu{
   width: 26px;
   height: 30px;
   cursor: pointer;
   display: block;
   position: absolute;
   right: 20px;
   top: 10px;
}
.Tgmenu span:nth-child(1){
   width:40%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 7px;
}
.Tgmenu span:nth-child(2){
   width:100%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 15px;
}
.Tgmenu span:nth-child(3){
   width:60%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 23px;
}
.bottomLogo{
   margin-top: 20px;
   text-align: center;
}
.bottomLogo img{
   max-width: 100%;
   height: auto;
}
.Tgmenu:hover span{
   width: 100%;
}
.leftMenu{
   padding: 15px 0;
   overflow: hidden;
}

.leftMenu ul{
   margin: 0;
   padding: 0;
   list-style-type: none;
}
.leftMenu ul li{
   margin: 0;
   padding: 0;
   list-style-type: none;
   width: 100%;
   height: 30px;
   font-size: 16px
}

.leftMenu ul li.sectionTitle{
   padding-left: 15px;
   list-style-type: none;
   width: 100%;
   height: 25px;
   color:#fff;
   font-size: 16px;
   text-transform: none!important;
   background: transparent!important;
   -webkit-transition: all 0s;
   transition: all 0s;
   position: relative;
}

.leftMenu ul li.sectionTitle button {
  background:none;
  padding:0;
  margin:0;
  position: absolute;
  top:0;
  right:8px;
  color:#fff;
  opacity: .64;
  border: 2px solid #fff;
  width:18px;
  height:18px;
  border-radius: 9px;
  outline: none!important;
  box-shadow: none!important;
}

.leftMenu ul li.sectionTitle button:hover {
  opacity: .84;
}

.leftMenu ul li.sectionTitle button i {
  margin:0;
  padding:0;
  font-size:10px;
  width:100%;
  text-align:center;
  line-height:14px;
  height:14px;
  display: block;
}

.leftMenu ul:last-child li.sectionTitle{
  margin-top:20px
}

.leftMenu ul li a {
   padding: 0px 15px;
   height: 30px;
   line-height: 30px;
   display: table;
   width: 220px;
   overflow: hidden;
   text-decoration:none !important;
}

.leftMenu ul li a span{
   color:#7e8592;
   display:table-cell;
   line-height: 30px;
}

.leftMenu ul li a i{
   text-align: left;
   width: 20px;
   font-size: 12px;
   display:table-cell;
   min-height: 30px;
   vertical-align: middle;
   color:#7e8592;
   position: relative;
   -webkit-transition: all ease-in-out 500ms;
   transition: all ease-in-out 500ms;
}

.leftMenu ul li a:hover{
   background-color:#303084;
   text-decoration: none;
}

.leftMenu ul li a:hover span{
   color:#fff;
}

.leftMenu ul li:hover i {
   color:#fff;
}


/* .leftMenu ul li a.active span, .leftMenu ul li a.active i {
   color: #fff;
}

.leftMenu ul li a.active {
   background-color:#303084;
} */

.LeftCover{
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background: rgba(0,0,0,.1);
   z-index:1000;
   display: none;
}

.nav.navbar-nav li a {
   color:#cac4c9;
   font-size: 15px;
}

@media only screen and (max-width:991px) {

}

@charset "utf-8";

body {
   background-color: #f9f9f9;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
   font-size: 16px;
   color:#2c2d30;
}

.loginPage {
   width: 100%;
   height: auto;
   padding: 0 20px;
}

#LoginContainer {
   width: 100%;
   max-width: 440px;
   height: auto;
   background-color: #fff;
   border-radius: 4px;
   box-shadow: 0 1px 0 rgba(0,0,0,.25);
   padding: 30px 15px;
   margin: 60px auto 30px;
   position: relative;
   border: 1px solid #e8e8e8;
}

#LoginContainer .title {
   color:#2c2d30;
   font-size: 32px;
   font-weight: 700;
   line-height: 32px;
   letter-spacing: -1px;
   text-align: center;
}

#LoginContainer .subLabel {
   color:#2c2d30;
   font-size: 16px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -1px;
   text-align: center;
   margin-top:40px;
   margin-bottom:20px;
}

#LoginContainer .formContainer {
   width: 100%;
   height:auto;
   margin: auto;
}

#LoginContainer .formContainer input {
   height:50px;
   width: 100%;
   border: 1px solid #919193; 
   margin-bottom:10px;
   font-size: 20px;
   font-weight: 400;
   color: #2c2d30;
   outline: none;
   box-shadow: none
}

#LoginContainer .formContainer input:focus {
   outline: none;
   box-shadow: none;
   border: 1px solid #666; 
}

#LoginContainer .formSubmit {
   height:50px;
   width: 100%;
   margin-top:20px;
   font-size: 20px;
   font-weight: 900;
   color: #fff;
   text-align: center;
   background:#008952;
   border-radius: 4px;
   line-height: 50px;
   cursor:pointer;
}


#composeField {
  width:100%;
}
.ql-container {
  white-space: pre-wrap;
  word-wrap: break-word;
  cursor:text;
  outline: none;
  text-align: left;
  border: 2px solid #717274!important;
  border-radius: 6px!important;
  font-size: 15px!important;
}

.ql-editor p {
  font-size: 15px!important;
}

#wonder-editor {
  white-space: pre-wrap;
  word-wrap: break-word;
  cursor:text;
  outline: none;
  text-align: left;
  border: 2px solid #717274;
  border-radius: 6px;
  font-size: 15px;
  line-height: 15px;
  min-height: 50px;
  padding:17px 20px;
  -webkit-transition: all ease-in-out 1000ms;
  transition: all ease-in-out 1000ms
}


#messagesContainer {
  width:100%;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  position:relative;
}
#messagesContainer .profilePicture {
  display: block;
  height: auto;
  float: left;
  width:36px;
  height: 36px;
  margin-right:8px;
  border-radius: 3px;
  background: #ececec
}
#messagesContainer .messageContainer {
  display: block;
  height: auto;
  float: left;
}

#messagesContainer ul {
  list-style-type: none;
  padding:0;
  margin:0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#messagesContainer ul li {
  padding:0;
  margin:0;
  margin-bottom:20px;
  width:100%
}

#messagesContainer ul li .messageContainer .userName {
  color:#2c2d30;
  font-size: 15px;
  font-weight:700;
  display:inline-block;
  padding-right:10px
}

#messagesContainer ul li .messageContainer .timeCreated {
  color: #717274;
  font-size: 12px;
  font-weight:400;
}

#messagesContainer ul li .messageContainer .message {
  color:#2c2d30;
  font-size: 15px;
  font-weight:400;
  line-height: 22px;
}

.threadUsers {
  border: 1px solid #b1b1b1;
  border-radius:4px;
  width:100%;
  min-height: 47px;
  margin-top:1px;
}

.threadUsers ul {
  list-style-type: none;
  margin:0
}
.threadUsers ul li {
  height: 35px;
  line-height: 35px;
  font-size:15px;
  cursor: pointer
}

@charset "utf-8";

/* 
.bg-corporateBlue { background: #2f2f81 }
.bg-corporateYellow { background: #f1cb08 }
.bg-corporateBlack { background: #171c1f }
 */
body {
  background-color: #f4f7fd;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #2c2d30;
  -webkit-print-color-adjust: exact !important;
}


.alert {
  font-size: 14px;
  padding: 8px 35px 8px 20px;
  color: #141922;
  border: 1px solid transparent;
  font-weight: 700;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.2); */
  position: absolute;
  top: 15px;
  right: -15px;
  text-align: right;
  /* f1cb08 */
  /* c0c3ca */
}

.RightSection {
  width: calc(100% - 220px);
  float: left;
  margin-left: 220px;
  -webkit-transition: all 0s;
  transition: all 0s;
  height: calc(100vh - 120px);
  position: relative;
  margin-top: 120px;
}

.primaryContent {
  position: absolute;
  bottom:0;
  width: 100%;
  height: auto;
  overflow: hidden;
  height: calc(100vh - 120px);
  padding: 15px;
  float: left;
  border-radius: 8px;
}

.overflow_ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.muteTitle {
  font-size: 35px;
  color: #9e9e9e;
  margin: 31px 0 30px;
  font-weight: 400;
}

.muteTitle strong {
  font-size: 35px;
  color: #616161;
  font-weight: 600;
}

ul.rainbowLarge {
  list-style-type: none;
}
ul.rainbowLarge li {
  max-width: 640px;
  width:100%;
  height:auto;
  
  border-radius: 13px;
  cursor: pointer;
  margin-bottom: 10px;
}
ul.rainbowLarge li a {
  width:100%;
  height: auto;
  padding: 14px 26px 17px;
  display: block;
}
ul.rainbowLarge li:nth-child(1) {background:#e5f3fd}
ul.rainbowLarge li:nth-child(2) {background:#f3f1ff}
ul.rainbowLarge li:nth-child(3) {background:#fef8e6}
ul.rainbowLarge li:nth-child(4) {background:#ebf9e9}
ul.rainbowLarge li:nth-child(5) {background:#fde2df}

ul.rainbowLarge li .title {
  color: #616161;
  font-size: 28px;
  height: 38px;
  text-decoration: none;
}

ul.rainbowLarge li .description {
  color: #9e9e9e;
  font-size: 17.5px;
  line-height: 19px;
  text-decoration: none;
}
ul.rainbowLarge li:hover:nth-child(1) {background:#0383fe}
ul.rainbowLarge li:hover:nth-child(2) {background:#886cfa}
ul.rainbowLarge li:hover:nth-child(3) {background:#f5bf1f}
ul.rainbowLarge li:hover:nth-child(4) {background:#37c13a}
ul.rainbowLarge li:hover:nth-child(5) {background:#f03638}
ul.rainbowLarge li:hover .title, ul.rainbowLarge li:hover .description  {color:#fff}

.mediaIcon {
  margin-right: 10px;
  font-size: 16px;
}

.full {
  width: 100%;
  margin-left: 0;
}

.fullSidebar {
  width: calc(100% - 220px);
  margin-left: 220px;
}

.fullSidebarClosed {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.fullSidebarSecondary {
  width: calc(100% - 220px - 400px);
  margin-left: 220px;
}

.fullSidebarClosedSeconday {
  width: calc(100% - 70px - 400px);
  margin-left: 70px;
}

.wondatron {
  width:100%;
  height:auto;
  background: #fff;
  padding:15px;
  border-radius: 5px;
}

.wondatron h1 {
  margin:0;
}

.wondatron ul.externalDate {
  width: 150px;
  height: auto;
  list-style-type: none;
  float: left;
  display: block;
}

.wondatron ul.externalDate li {
  width:100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color:rgb(71, 71, 71);
  cursor:pointer;
  padding: 2px 5px 2px 8px;
  font-weight: 600;
  margin-right:10px;
  border-radius: 4px;
}
.wondatron ul.externalDate li:hover {
  background: #e5f3fd;
  color:#0383fe;
}

.DateRangePicker {
  border-left: 1px solid #ececec;
  padding-top:10px;
}

.DateRangePicker .DateRangePicker__Month {
  margin:0;
  margin-right:10px;
}

.DateRangePicker .DateRangePicker__Month:first-child {
  margin-right:10px;
}

.DateRangePicker__PaginationArrow--previous {
  left: 0;
}
.DateRangePicker__PaginationArrow--next {
  right: 0;
}

table.DateRangePicker__MonthDates>thead>tr {
  background-color: transparent;
  font-size: 16px;
  border: none;
  height: 30px;
  color:#0383fe!important;
}
.DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: #0383fe;
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
}
.DateRangePicker__WeekdayHeading {
  border: none;
  color: #0383fe;
  font-weight: bold;
}
.DateRangePicker__Date {
  border: none;
}
.DateRangePicker__Date--otherMonth {
  opacity: .25;
}
.DateRangePicker__Date--weekend {
  background: transparent!important;
}
table tr:nth-child(even) {
  background:transparent;
}

@media only screen and (min-width:992px) {
  .MenuC .primaryContent {
    width: calc(100% - 430px);
  }
}

@media only screen and (max-width:991px) {

}

.clearfix:after, .clearfix:before {
  content: "";
  display: table
}

.clearfix:after {
  clear: both
}

.clearfix {
  zoom: 1
}

a:hover {
  text-decoration: none;
}

.ani-1, .ani-1 span, .ani-1 ul li {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ani-1 a {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ani-1 a:hover, .ani-1 a:hover span, .ani-1 a:hover ul li {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.no-ani {
  -webkit-transition: all 0s;
  transition: all 0s;
}

.buffer-bottom {
  margin-bottom: 30px;
}

.buffer-bottom-d {
  margin-bottom: 60px;
}

.buffer-top {
  margin-top: 30px;
}

.buffer-top-d {
  margin-top: 60px;
}

.printStats {
  display: none;
}

.printTitle {
  display:none;
}

.pieTitle {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.hide {
  display:none;
}

@media print {
  
  body, .secondaryContent .heading {
    background: #fff!important;
  }
  .actionsBar {
    display:none!important;
  }
  
  .RightSection {
    float: left;
    -webkit-transition: all 0s;
    transition: all 0s;
    height: auto;
    position: relative;
    width: 100%;
    margin-left: 0;
  }

  .LeftSection {
    display:none!important;
  }

  header {
    display:none!important;
  }

  .RightSection {
    margin-top:0px!important;
  }

  .screenContent {
    display:none!important;
  }

  .printStats table td {
    border-top:1px solid #e8e8e8;
  }
  table td {
    font-size:12pt!important;
    padding:7px!important;
  }
  
  .boxTile h2 {
    padding:0;
    margin:0;
    font-size: 20pt;
  }
  
  .mt-30 {
    margin-bottom: 10px;
  }
  .printTitle {
    display:block!important;
  }
  
  .printTitle h1 {
    font-size: 36px;
    font-weight: 400;
    color:purple
  }
  
  .printTitle p {
    font-size: 18px;
    font-weight: 400;
    color:#777777;
    margin:0;
    padding:0;
  }
  
  .printTitle p.bold {
    font-size: 18px;
    font-weight: 700;
    color:#333333;
    margin-top: 10px;
  }
  
  .screenStats {
    display: none!important;
  }
  .printStats {
    display: block;
  }
  .printStats table {
    width:100%;
  }
  .printStats table td {
    width:25%;
  }
  .primaryContent {
    bottom: 0;
    width: 100%;
    float: left;
    border-radius: 8px;
    top: 0;
    height: auto;
    overflow: scroll;
    position: relative;
  }
  .RightSection {
    margin-top:0px!important;
  }


  .screenPrint .col-md-3 {

    display: table-cell;
  }

  .lineBreak {
    page-break-after: always;
  }

  .boxTile h2 {
    font-size: 14px;
  }

  .boxTile.shadow p {
    font-size: 11px;
  }

  .progressContainer .col-md-6 {
    display: table-cell;
    width: 50%;
  }
  .col-md-4, .col-md-4 .boxTile {
    display: table-cell;
    width: 25%;
  }

  .boxTile table th, .boxTile table td {
    font-size: 10px!important;
  }

}


#threadContainer {
  position:absolute;
  bottom:20px;
  width:calc(100% - 30px);
  height: auto;
}


.btn-success {
    color: #fff;
    background-color: #008952;
    border-color: #008952;
    outline:none!important;
    box-shadow: none
}
.btn-success:hover {
    color: #fff;
    background-color: #037b4b;
    border-color: #037b4b;
    box-shadow: none
}
.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open>.dropdown-toggle.btn-success.focus, .open>.dropdown-toggle.btn-success:focus, .open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #008952;
    border-color: #008952;
}


.btn-default {
    color: #333;
    background:none;
    border-color: #ccc;
}
.btn-default:hover {
    color: #333;
    background:none;
    border-color: #adadad;
}
.btn-default.focus, .btn-default:focus {
    color: #333;
    background:none;
    border-color: #8c8c8c;
}

.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    color: #333;
    background: none;
    border-color: #adadad;
}

.boxTile {
   width: 100%;
   background:#fff;
   border-radius: 5px;
   position: relative;
   cursor: pointer;
   padding:20px;
}
.boxTile.shadow{
    box-shadow: 0 0 4px rgba(0,0,0, .14);
}
.boxTile:hover {  
    box-shadow: 0 0 4px rgba(0,0,0, .14);
 }
.boxTile .iconCirle {
    width:42px;
    height:42px;
    border-radius: 21px;
    margin: 0 auto;
    display:block;
    position: relative;
    top:30px;
}
.boxTile .iconCirle i {
    width:100%;
    text-align: center;
    line-height:42px;
    font-size: 18px;
}

.boxTile .megaTitle {
    width:100%;
    text-align: center;
    font-size: 300%;
    margin-top:30px;
    color:#2d2f39;
    font-weight: 600;
}

.boxTile .subTitle {
    width:100%;
    text-align: center;
    font-size: 14px;
    color:#84888b;
}

.reports.boxTile {
    height:60px;
    min-height:60px;
}
.boxTile-extras{display: none}
.reports.boxTile.open {
    height: auto;
    min-height:150px;
    padding:20px;
    background: #eef9f0;
    box-shadow: 0 0 4px rgba(0,0,0, .24)
}
.reports.boxTile .subTitle {
    text-align: left;
    font-size: 18px;
    color: #333;
}
.reports.boxTile .shortDivider {
    width:5px;
    height: 5px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    margin-top: 8px;
    -webkit-transition: all ease-in-out 600ms;
    transition: all ease-in-out 600ms;
}

.reports.boxTile.open .shortDivider {
    width:60px;
    background: #37c13a;
}

.reports.boxTile.open .boxTile-extras{display: block}

.datePickerPop {
    float: right;
    width: auto;
    height: 35px;
    line-height: 35px;
    color: #333;
    background: #fff;
    border-radius: 5px;
    padding: 0 35px 0 20px;
    position: relative;
    cursor: pointer;
    margin-top:20px;
}

.datePickerPop i {
    position: absolute;
    top:10px;
    right: 10px;
}

.exportBlock ul li{
    font-weight: 600
}
.exportBlock ul li:hover{
    color:#303084;
}
.react-daterange-picker__wrapper {
    border:none;
    border-bottom: 2px dashed #333;
    padding-bottom: 10px;
}
@charset "utf-8";

h1, h2, h3, h4, h5 {
    width: auto;
    display: inline-block;
}

h1 { font-size: 36px; }
h2 { font-size: 30px; }
h3 { font-size: 24px; }
h4 { font-size: 18px; }

.bg-corporateBlack { background: #171c1f }
.color-corporateBlack { color: #171c1f }
.bg-corporateBlue { background: #303084 }
.color-corporateBlue { color: #303084 }
.bg-corporateYellow { background: #f5cd08 }
.color-corporateYellow { color: #f5cd08 }

.bg-lightBlue { background: #e5f3fd;}
.color-Blue { color: #0383fe;}
.bg-lightPurlple { background: #f3f1ff;}
.color-Purlple { color: #886cfa;}
.bg-lightYellow { background: #fef8e6;}
.color-Yellow { color: #f5bf1f;}
.bg-lightGreen { background: #ebf9e9;}
.color-Green { color: #37c13a;}
.bg-Green { color: #37c13a;}
.bg-lightRed { background: #fde2df;}
.color-Red { color: #f03638;}
.bg-Red { background: #f03638;}
.bg-White { background: #fff;}
.color-White { color: #fff;}

.bold {font-weight:600;}
.regular {font-weight:400;}
.light {font-weight:300;}

.mb-0{margin-bottom:0}
.mb-5{margin-bottom:5px}
.mb-10{margin-bottom:10px}
.mb-15{margin-bottom:15px}
.mb-20{margin-bottom:20px}
.mb-25{margin-bottom:25px}
.mb-30{margin-bottom:30px}
.mb-35{margin-bottom:35px}
.mb-40{margin-bottom:40px}
.mb-50{margin-bottom:50px}
.mb-60{margin-bottom:60px}
.mb-80{margin-bottom:80px}

.mt-0{margin-top:0}
.mt-5{margin-top:5px}
.mt-10{margin-top:10px}
.mt-15{margin-top:15px}
.mt-20{margin-top:20px}
.mt-25{margin-top:25px}
.mt-30{margin-top:30px}
.mt-35{margin-top:35px}
.mt-40{margin-top:40px}
.mt-50{margin-top:50px}
.mt-60{margin-top:60px}
.mt-80{margin-top:80px}

.minHeight-5{min-height:5px}
.minHeight-10{min-height:10px}
.minHeight-15{min-height:15px}
.minHeight-20{min-height:20px}
.minHeight-25{min-height:25px}
.minHeight-30{min-height:30px}
.minHeight-35{min-height:35px}
.minHeight-40{min-height:40px}
.minHeight-50{min-height:50px}
.minHeight-60{min-height:60px}
.minHeight-80{min-height:80px}
.minHeight-90{min-height:90px}
.minHeight-100{min-height:100px}
.minHeight-120{min-height:120px}
.minHeight-140{min-height:140px}
.minHeight-160{min-height:160px}
.minHeight-180{min-height:180px}
.minHeight-200{min-height:200px}
.minHeight-250{min-height:250px}
.minHeight-300{min-height:300px}
.minHeight-350{min-height:350px}
.minHeight-400{min-height:400px}
.minHeight-450{min-height:450px}
.minHeight-500{min-height:500px}

table tr{
   font-weight:700;
   font-size: 15px;
}

table tr:nth-child(even){
   background-color: #f9f9f9;
}

table tr{
   padding-bottom:7px !important;
   padding-top:7px !important;
}

table>thead>tr{
   /* background-color: #ececec; */
   font-size: 16px;
   border-bottom: 1px solid #e2e2e2;
   height:59px;
}
table>thead>tr>th:nth-child(1){
   padding-left: 15px;
}

table>tbody>tr>td{
   font-size: 14px;
   border-bottom: 1px solid #e8e8e8;
   font-weight:400;
   cursor: pointer;
}

table>tbody>tr>td, table>tbody>tr>th, table>tfoot>tr>td, table>tfoot>tr>th, table>thead>tr>td, table>thead>tr>th {
   padding:12px 8px;
}




@media only screen and (min-width: 300px) and (max-width:767px) {

   table>tbody>tr>td, table>tbody>tr>th, table>tfoot>tr>td, table>tfoot>tr>th, table>thead>tr>td, table>thead>tr>th {
   padding:5px 5px !important;
      display: block;
      width: 100%;
   }

   table>tbody>tr>td:nth-last-child(2){
      font-size: 14px;
      text-align: justify;
   }

}
 










@charset "utf-8";

.forms section {
   max-width:640px;
   height:auto;
   position: relative;
   margin: 20px 0 0;
}

.forms  h1 {
   font-size: 34px;
   font-weight: 900;
   height:34px;
   padding:0;
   margin:0;
}

.forms .subHeading{
   color:#717274;
   font-size: 15px;
   font-weight: 300;
   margin:25px 0 30px;
}

.forms .form-group {
   margin-bottom:20px;
   position: relative;
}

.forms .btn-toolbar {
   padding-top:20px;
}

.forms label {
   font-size: 16px;
   font-weight:600;
}

.forms .form-control {
   height:47px;
   outline: none!important;
   box-shadow: none;
   border: 1px solid #919193;
   font-size: 20px;
   color: #2c2d30;
   font-weight:400;
}

.forms .form-group ::-webkit-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.forms .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.forms .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }

.forms .form-group ::placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .helpText {
   font-size: 12px;
   min-height:12px;
   color:#717274;
   margin-top:8px;
   font-weight: 400;
}

.forms .radioSelector {
   height:55px;
   width:100%;
   border: 1px solid #e8e8e8;
   background: #f9f9f9;
   border-radius: 5px;
   margin-bottom: 8px;
   overflow: hidden;
   position: relative;
   cursor: pointer;
}

.forms .radioSelector.active, .forms .radioSelector:hover {
   border: 1px solid #2d9ee0;
   background: #eaf5fc;
}

.forms .radioSelector input[type=radio]{
   position: absolute;
   left:13px;
   top:13px;
}

.forms .radioSelector label {
   margin: 0;
   width: 100%;
   position: relative;
   padding-left:36px;
   padding-top:8px;
   cursor: pointer;
   height: 55px;
}

.forms .radioSelector label.noDescription {
   padding-top:15px;
}

.forms .radioSelector label span {
   font-size: 16px;
   font-weight: 600;


}

.forms .radioSelector .subLabel {
   font-size: 15px;
   color: #717274;
   font-weight: 300;
}

.forms .conditionOperator {
   text-align: center;
   font-size:24px;
   font-weight:700;
}


/* Toggle */
.react-toggle-track {
  position:relative
}
.react-toggle-thumb {
  -webkit-transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px!important;
  left: 2px!important;
  width: 23px;
  height: 23px;
  border: none!important;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none!important
}
.react-toggle-track {
    width: 64px!important;
    height: 26px!important;
    background-color: #f26130!important;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.react-toggle--checked .react-toggle-track {
    background-color: #008952!important;
}
.react-toggle--checked .react-toggle-thumb {
    left: 40px!important;
}
.react-toggle {
  margin-right:15px;
}
.toggle-group label span {
  font-size: 16px!important;
  line-height: 20px;
  margin: 0;
  padding: 0;
  height: 20px;
  position: relative;
  top: -6px;
  font-weight: 400
}

.appsContainer {
    width:100%;
    height: auto;
    background: #f5f8fa;
    padding-bottom: 50px;
}

.appsContainer .col-md-3, .appsContainer .col-md-6 {
    padding-right: 4px;
    padding-left: 4px;
}

.appsContainer .row {
    margin-right: -4px;
    margin-left: -4px;
}

.appsContainer .container {
    max-width: 1000px;
}

.appsContainer h1 {
    margin-top: 50px;
    color: #616161;
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    width: 100%;
}
.appsContainer h1 span {
    color: #9e9e9e;
    font-weight: 400;
}

.appsContainer p {
    color: #545454;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
}

.appsContainer .appBox {
    height: 320px;
    width: 100%;
    background: #fff;
    border-radius: 13px;
    box-shadow: 0 0 5px rgba(0,0,0, .17);
    position: relative;
    margin-bottom: 8px;
    -webkit-transition: all ease-in-out .1s;
    transition: all ease-in-out .1s;
    cursor:pointer;
    display: block;
}

.appsContainer .appBox:hover {
    box-shadow: 0 0 8px rgba(0,0,0, .4);
}

.appsContainer .appBox .title {
    width: 100%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: 70px;
    font-size: 25px;
    font-weight: 400;
    color: #1b1c1f;
    line-height: 27px;
}

.appsContainer .appBox .actionBtnContainer {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

.appsContainer .appBox .actionBtn {
    width: 120px;
    height: 30px;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    font-size: 16px;
    display: block;
}

.appsContainer .appBox .actionBtn.active {
    background: #30327f;
    color: #fff;
    cursor: pointer;
}

.appsContainer .appBox .actionBtn.disabled {
    background: #ccc;
    color: rgb(85, 85, 85);
    cursor: not-allowed;
}

.appsContainer .appBox  .iconContainer {
    width: 147px;
    height: 147px;
    top: 25px;
    position: relative;
    margin: 0 auto;
}

.appsContainer .appBox  .iconBox {
    width: 147px;
    height: 147px;
    border-radius: 100%;
    border: 4px solid #1b1c1f;
    top: 0;
    left:0;
    position: absolute;
    z-index: 5;
    text-align: center;
}
.appsContainer .appBox .iconBox img {
    margin-top: 34px;
}


.appsContainer .appBox .iconBg {
    width: 142px;
    height: 142px;
    border-radius: 100%;
    background: #fdeeb3;
    top: 10px;
    left: 9px;
    position: absolute;
    z-index: 4;
}


@media only screen and (max-width:480px) {

    .appsContainer h1 {
        margin-top: 30px;
        color: #1b1c1f;
        font-size: 36px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .appsContainer p {
        color: #1b1c1f;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 25px;
    }
}
