
.btn-success {
    color: #fff;
    background-color: #008952;
    border-color: #008952;
    outline:none!important;
    box-shadow: none
}
.btn-success:hover {
    color: #fff;
    background-color: #037b4b;
    border-color: #037b4b;
    box-shadow: none
}
.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open>.dropdown-toggle.btn-success.focus, .open>.dropdown-toggle.btn-success:focus, .open>.dropdown-toggle.btn-success:hover {
    color: #fff;
    background-color: #008952;
    border-color: #008952;
}


.btn-default {
    color: #333;
    background:none;
    border-color: #ccc;
}
.btn-default:hover {
    color: #333;
    background:none;
    border-color: #adadad;
}
.btn-default.focus, .btn-default:focus {
    color: #333;
    background:none;
    border-color: #8c8c8c;
}

.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    color: #333;
    background: none;
    border-color: #adadad;
}
