@charset "utf-8";

.forms section {
   max-width:640px;
   height:auto;
   position: relative;
   margin: 20px 0 0;
}

.forms  h1 {
   font-size: 34px;
   font-weight: 900;
   height:34px;
   padding:0;
   margin:0;
}

.forms .subHeading{
   color:#717274;
   font-size: 15px;
   font-weight: 300;
   margin:25px 0 30px;
}

.forms .form-group {
   margin-bottom:20px;
   position: relative;
}

.forms .btn-toolbar {
   padding-top:20px;
}

.forms label {
   font-size: 16px;
   font-weight:600;
}

.forms .form-control {
   height:47px;
   outline: none!important;
   box-shadow: none;
   border: 1px solid #919193;
   font-size: 20px;
   color: #2c2d30;
   font-weight:400;
}

.forms .form-group ::placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.forms .helpText {
   font-size: 12px;
   min-height:12px;
   color:#717274;
   margin-top:8px;
   font-weight: 400;
}

.forms .radioSelector {
   height:55px;
   width:100%;
   border: 1px solid #e8e8e8;
   background: #f9f9f9;
   border-radius: 5px;
   margin-bottom: 8px;
   overflow: hidden;
   position: relative;
   cursor: pointer;
}

.forms .radioSelector.active, .forms .radioSelector:hover {
   border: 1px solid #2d9ee0;
   background: #eaf5fc;
}

.forms .radioSelector input[type=radio]{
   position: absolute;
   left:13px;
   top:13px;
}

.forms .radioSelector label {
   margin: 0;
   width: 100%;
   position: relative;
   padding-left:36px;
   padding-top:8px;
   cursor: pointer;
   height: 55px;
}

.forms .radioSelector label.noDescription {
   padding-top:15px;
}

.forms .radioSelector label span {
   font-size: 16px;
   font-weight: 600;


}

.forms .radioSelector .subLabel {
   font-size: 15px;
   color: #717274;
   font-weight: 300;
}

.forms .conditionOperator {
   text-align: center;
   font-size:24px;
   font-weight:700;
}


/* Toggle */
.react-toggle-track {
  position:relative
}
.react-toggle-thumb {
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px!important;
  left: 2px!important;
  width: 23px;
  height: 23px;
  border: none!important;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none!important
}
.react-toggle-track {
    width: 64px!important;
    height: 26px!important;
    background-color: #f26130!important;
    transition: all 0.2s ease;
}
.react-toggle--checked .react-toggle-track {
    background-color: #008952!important;
}
.react-toggle--checked .react-toggle-thumb {
    left: 40px!important;
}
.react-toggle {
  margin-right:15px;
}
.toggle-group label span {
  font-size: 16px!important;
  line-height: 20px;
  margin: 0;
  padding: 0;
  height: 20px;
  position: relative;
  top: -6px;
  font-weight: 400
}
