@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

/* 
.bg-corporateBlue { background: #2f2f81 }
.bg-corporateYellow { background: #f1cb08 }
.bg-corporateBlack { background: #171c1f }
 */
body {
  background-color: #f4f7fd;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #2c2d30;
  -webkit-print-color-adjust: exact !important;
}


.alert {
  font-size: 14px;
  padding: 8px 35px 8px 20px;
  color: #141922;
  border: 1px solid transparent;
  font-weight: 700;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.2); */
  position: absolute;
  top: 15px;
  right: -15px;
  text-align: right;
  /* f1cb08 */
  /* c0c3ca */
}

.RightSection {
  width: calc(100% - 220px);
  float: left;
  margin-left: 220px;
  transition: all 0s;
  height: calc(100vh - 120px);
  position: relative;
  margin-top: 120px;
}

.primaryContent {
  position: absolute;
  bottom:0;
  width: 100%;
  height: auto;
  overflow: hidden;
  height: calc(100vh - 120px);
  padding: 15px;
  float: left;
  border-radius: 8px;
}

.overflow_ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.muteTitle {
  font-size: 35px;
  color: #9e9e9e;
  margin: 31px 0 30px;
  font-weight: 400;
}

.muteTitle strong {
  font-size: 35px;
  color: #616161;
  font-weight: 600;
}

ul.rainbowLarge {
  list-style-type: none;
}
ul.rainbowLarge li {
  max-width: 640px;
  width:100%;
  height:auto;
  
  border-radius: 13px;
  cursor: pointer;
  margin-bottom: 10px;
}
ul.rainbowLarge li a {
  width:100%;
  height: auto;
  padding: 14px 26px 17px;
  display: block;
}
ul.rainbowLarge li:nth-child(1) {background:#e5f3fd}
ul.rainbowLarge li:nth-child(2) {background:#f3f1ff}
ul.rainbowLarge li:nth-child(3) {background:#fef8e6}
ul.rainbowLarge li:nth-child(4) {background:#ebf9e9}
ul.rainbowLarge li:nth-child(5) {background:#fde2df}

ul.rainbowLarge li .title {
  color: #616161;
  font-size: 28px;
  height: 38px;
  text-decoration: none;
}

ul.rainbowLarge li .description {
  color: #9e9e9e;
  font-size: 17.5px;
  line-height: 19px;
  text-decoration: none;
}
ul.rainbowLarge li:hover:nth-child(1) {background:#0383fe}
ul.rainbowLarge li:hover:nth-child(2) {background:#886cfa}
ul.rainbowLarge li:hover:nth-child(3) {background:#f5bf1f}
ul.rainbowLarge li:hover:nth-child(4) {background:#37c13a}
ul.rainbowLarge li:hover:nth-child(5) {background:#f03638}
ul.rainbowLarge li:hover .title, ul.rainbowLarge li:hover .description  {color:#fff}

.mediaIcon {
  margin-right: 10px;
  font-size: 16px;
}

.full {
  width: 100%;
  margin-left: 0;
}

.fullSidebar {
  width: calc(100% - 220px);
  margin-left: 220px;
}

.fullSidebarClosed {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.fullSidebarSecondary {
  width: calc(100% - 220px - 400px);
  margin-left: 220px;
}

.fullSidebarClosedSeconday {
  width: calc(100% - 70px - 400px);
  margin-left: 70px;
}

.wondatron {
  width:100%;
  height:auto;
  background: #fff;
  padding:15px;
  border-radius: 5px;
}

.wondatron h1 {
  margin:0;
}

.wondatron ul.externalDate {
  width: 150px;
  height: auto;
  list-style-type: none;
  float: left;
  display: block;
}

.wondatron ul.externalDate li {
  width:100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color:rgb(71, 71, 71);
  cursor:pointer;
  padding: 2px 5px 2px 8px;
  font-weight: 600;
  margin-right:10px;
  border-radius: 4px;
}
.wondatron ul.externalDate li:hover {
  background: #e5f3fd;
  color:#0383fe;
}

.DateRangePicker {
  border-left: 1px solid #ececec;
  padding-top:10px;
}

.DateRangePicker .DateRangePicker__Month {
  margin:0;
  margin-right:10px;
}

.DateRangePicker .DateRangePicker__Month:first-child {
  margin-right:10px;
}

.DateRangePicker__PaginationArrow--previous {
  left: 0;
}
.DateRangePicker__PaginationArrow--next {
  right: 0;
}

table.DateRangePicker__MonthDates>thead>tr {
  background-color: transparent;
  font-size: 16px;
  border: none;
  height: 30px;
  color:#0383fe!important;
}
.DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: #0383fe;
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
}
.DateRangePicker__WeekdayHeading {
  border: none;
  color: #0383fe;
  font-weight: bold;
}
.DateRangePicker__Date {
  border: none;
}
.DateRangePicker__Date--otherMonth {
  opacity: .25;
}
.DateRangePicker__Date--weekend {
  background: transparent!important;
}
table tr:nth-child(even) {
  background:transparent;
}

@media only screen and (min-width:992px) {
  .MenuC .primaryContent {
    width: calc(100% - 430px);
  }
}

@media only screen and (max-width:991px) {

}

.clearfix:after, .clearfix:before {
  content: "";
  display: table
}

.clearfix:after {
  clear: both
}

.clearfix {
  zoom: 1
}

a:hover {
  text-decoration: none;
}

.ani-1, .ani-1 span, .ani-1 ul li {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ani-1 a {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ani-1 a:hover, .ani-1 a:hover span, .ani-1 a:hover ul li {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.no-ani {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  -ms-transition: all 0s;
  transition: all 0s;
}

.buffer-bottom {
  margin-bottom: 30px;
}

.buffer-bottom-d {
  margin-bottom: 60px;
}

.buffer-top {
  margin-top: 30px;
}

.buffer-top-d {
  margin-top: 60px;
}

.printStats {
  display: none;
}

.printTitle {
  display:none;
}

.pieTitle {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.hide {
  display:none;
}

@media print {
  
  body, .secondaryContent .heading {
    background: #fff!important;
  }
  .actionsBar {
    display:none!important;
  }
  
  .RightSection {
    float: left;
    transition: all 0s;
    height: auto;
    position: relative;
    width: 100%;
    margin-left: 0;
  }

  .LeftSection {
    display:none!important;
  }

  header {
    display:none!important;
  }

  .RightSection {
    margin-top:0px!important;
  }

  .screenContent {
    display:none!important;
  }

  .printStats table td {
    border-top:1px solid #e8e8e8;
  }
  table td {
    font-size:12pt!important;
    padding:7px!important;
  }
  
  .boxTile h2 {
    padding:0;
    margin:0;
    font-size: 20pt;
  }
  
  .mt-30 {
    margin-bottom: 10px;
  }
  .printTitle {
    display:block!important;
  }
  
  .printTitle h1 {
    font-size: 36px;
    font-weight: 400;
    color:purple
  }
  
  .printTitle p {
    font-size: 18px;
    font-weight: 400;
    color:#777777;
    margin:0;
    padding:0;
  }
  
  .printTitle p.bold {
    font-size: 18px;
    font-weight: 700;
    color:#333333;
    margin-top: 10px;
  }
  
  .screenStats {
    display: none!important;
  }
  .printStats {
    display: block;
  }
  .printStats table {
    width:100%;
  }
  .printStats table td {
    width:25%;
  }
  .primaryContent {
    bottom: 0;
    width: 100%;
    float: left;
    border-radius: 8px;
    top: 0;
    height: auto;
    overflow: scroll;
    position: relative;
  }
  .RightSection {
    margin-top:0px!important;
  }


  .screenPrint .col-md-3 {

    display: table-cell;
  }

  .lineBreak {
    page-break-after: always;
  }

  .boxTile h2 {
    font-size: 14px;
  }

  .boxTile.shadow p {
    font-size: 11px;
  }

  .progressContainer .col-md-6 {
    display: table-cell;
    width: 50%;
  }
  .col-md-4, .col-md-4 .boxTile {
    display: table-cell;
    width: 25%;
  }

  .boxTile table th, .boxTile table td {
    font-size: 10px!important;
  }

}