.secondaryContent .content .section label {
   font-size: 14px;
   line-height:14px;
   font-weight: 500;
   color: #2d85b9;
   margin-bottom:3px;
}

.secondaryContent .content .section .titleAns {
   font-size: 16px;
   line-height:16px;
   font-weight: 400;
   color:#5f5f5f;
   margin-bottom:10px;
}

