@charset "utf-8";

body {
   background-color: #f9f9f9;
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
   -webkit-font-smoothing: antialiased;
   font-size: 16px;
   color:#2c2d30;
}

.loginPage {
   width: 100%;
   height: auto;
   padding: 0 20px;
}

#LoginContainer {
   width: 100%;
   max-width: 440px;
   height: auto;
   background-color: #fff;
   border-radius: 4px;
   box-shadow: 0 1px 0 rgba(0,0,0,.25);
   padding: 30px 15px;
   margin: 60px auto 30px;
   position: relative;
   border: 1px solid #e8e8e8;
}

#LoginContainer .title {
   color:#2c2d30;
   font-size: 32px;
   font-weight: 700;
   line-height: 32px;
   letter-spacing: -1px;
   text-align: center;
}

#LoginContainer .subLabel {
   color:#2c2d30;
   font-size: 16px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -1px;
   text-align: center;
   margin-top:40px;
   margin-bottom:20px;
}

#LoginContainer .formContainer {
   width: 100%;
   height:auto;
   margin: auto;
}

#LoginContainer .formContainer input {
   height:50px;
   width: 100%;
   border: 1px solid #919193; 
   margin-bottom:10px;
   font-size: 20px;
   font-weight: 400;
   color: #2c2d30;
   outline: none;
   box-shadow: none
}

#LoginContainer .formContainer input:focus {
   outline: none;
   box-shadow: none;
   border: 1px solid #666; 
}

#LoginContainer .formSubmit {
   height:50px;
   width: 100%;
   margin-top:20px;
   font-size: 20px;
   font-weight: 900;
   color: #fff;
   text-align: center;
   background:#008952;
   border-radius: 4px;
   line-height: 50px;
   cursor:pointer;
}
