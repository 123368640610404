header {
   display: block;
   min-height: 70px;
   background-color: #fff;
   padding: 0;
   position: fixed;
   top: 0;
   right: 0;
   left: 220px;
   border-bottom: 1px solid #e5e5e5;
   z-index: 3;
}

.titleSection {
   margin:10px 0 0 20px;
   display:inline-block;
}

.HeaderTitle {
   font-weight: 700;
   font-size:18px;
   color:#000;
   float:none;
}

.HeaderSubTitle {
   font-weight: 400;
   font-size:13px;
   color:#717274;
   margin-top:2px;
   float:none;
}

#quickAdd {
   -webkit-box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
   box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
   display: -ms-inline-flexbox;
   display: inline-flex;
   font-weight: 500;
   height: 48px;
   width: 48px;
   border-radius: 24px;
   color: #3c4043;
   background-color: #fff;
   text-align: center;
   cursor: pointer;
   font-weight: 600;
   -webkit-transition: ease-in-out .3s;
   -o-transition: ease-in-out .3s;
   transition: ease-in-out .3s;
   margin: 12px 12px 0 10px;
   float:right;
}
#quickAdd:hover {
    -webkit-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
    background-color: #fafafb;
    text-decoration: none!important;
}

#quickAdd i {
   background-size: 20px;
   content: '';
   display: block;
   border-radius: 50%;
   height: 48px;
   width: 48px;
   text-align: center;
   line-height: 48px;
   font-size: 20px;
   color: #fb5a5f;
}


.actionsBar {
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 10px 20px;
  position: fixed;
  top: 70px;
  width: 100%;
  height: 50px;
  line-height: 20px;
  z-index: 1;
  font-size: 14px;
  color:#666
}
