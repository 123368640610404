.appsContainer {
    width:100%;
    height: auto;
    background: #f5f8fa;
    padding-bottom: 50px;
}

.appsContainer .col-md-3, .appsContainer .col-md-6 {
    padding-right: 4px;
    padding-left: 4px;
}

.appsContainer .row {
    margin-right: -4px;
    margin-left: -4px;
}

.appsContainer .container {
    max-width: 1000px;
}

.appsContainer h1 {
    margin-top: 50px;
    color: #616161;
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
    width: 100%;
}
.appsContainer h1 span {
    color: #9e9e9e;
    font-weight: 400;
}

.appsContainer p {
    color: #545454;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
}

.appsContainer .appBox {
    height: 320px;
    width: 100%;
    background: #fff;
    border-radius: 13px;
    box-shadow: 0 0 5px rgba(0,0,0, .17);
    position: relative;
    margin-bottom: 8px;
    transition: all ease-in-out .1s;
    cursor:pointer;
    display: block;
}

.appsContainer .appBox:hover {
    box-shadow: 0 0 8px rgba(0,0,0, .4);
}

.appsContainer .appBox .title {
    width: 100%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: 70px;
    font-size: 25px;
    font-weight: 400;
    color: #1b1c1f;
    line-height: 27px;
}

.appsContainer .appBox .actionBtnContainer {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

.appsContainer .appBox .actionBtn {
    width: 120px;
    height: 30px;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    font-size: 16px;
    display: block;
}

.appsContainer .appBox .actionBtn.active {
    background: #30327f;
    color: #fff;
    cursor: pointer;
}

.appsContainer .appBox .actionBtn.disabled {
    background: #ccc;
    color: rgb(85, 85, 85);
    cursor: not-allowed;
}

.appsContainer .appBox  .iconContainer {
    width: 147px;
    height: 147px;
    top: 25px;
    position: relative;
    margin: 0 auto;
}

.appsContainer .appBox  .iconBox {
    width: 147px;
    height: 147px;
    border-radius: 100%;
    border: 4px solid #1b1c1f;
    top: 0;
    left:0;
    position: absolute;
    z-index: 5;
    text-align: center;
}
.appsContainer .appBox .iconBox img {
    margin-top: 34px;
}


.appsContainer .appBox .iconBg {
    width: 142px;
    height: 142px;
    border-radius: 100%;
    background: #fdeeb3;
    top: 10px;
    left: 9px;
    position: absolute;
    z-index: 4;
}


@media only screen and (max-width:480px) {

    .appsContainer h1 {
        margin-top: 30px;
        color: #1b1c1f;
        font-size: 36px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .appsContainer p {
        color: #1b1c1f;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 25px;
    }
}