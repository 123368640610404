
#composeField {
  width:100%;
}
.ql-container {
  white-space: pre-wrap;
  word-wrap: break-word;
  cursor:text;
  outline: none;
  text-align: left;
  border: 2px solid #717274!important;
  border-radius: 6px!important;
  font-size: 15px!important;
}

.ql-editor p {
  font-size: 15px!important;
}

#wonder-editor {
  white-space: pre-wrap;
  word-wrap: break-word;
  cursor:text;
  outline: none;
  text-align: left;
  border: 2px solid #717274;
  border-radius: 6px;
  font-size: 15px;
  line-height: 15px;
  min-height: 50px;
  padding:17px 20px;
  transition: all ease-in-out 1000ms
}
