@charset "utf-8";

h1, h2, h3, h4, h5 {
    width: auto;
    display: inline-block;
}

h1 { font-size: 36px; }
h2 { font-size: 30px; }
h3 { font-size: 24px; }
h4 { font-size: 18px; }

.bg-corporateBlack { background: #171c1f }
.color-corporateBlack { color: #171c1f }
.bg-corporateBlue { background: #303084 }
.color-corporateBlue { color: #303084 }
.bg-corporateYellow { background: #f5cd08 }
.color-corporateYellow { color: #f5cd08 }

.bg-lightBlue { background: #e5f3fd;}
.color-Blue { color: #0383fe;}
.bg-lightPurlple { background: #f3f1ff;}
.color-Purlple { color: #886cfa;}
.bg-lightYellow { background: #fef8e6;}
.color-Yellow { color: #f5bf1f;}
.bg-lightGreen { background: #ebf9e9;}
.color-Green { color: #37c13a;}
.bg-Green { color: #37c13a;}
.bg-lightRed { background: #fde2df;}
.color-Red { color: #f03638;}
.bg-Red { background: #f03638;}
.bg-White { background: #fff;}
.color-White { color: #fff;}

.bold {font-weight:600;}
.regular {font-weight:400;}
.light {font-weight:300;}

.mb-0{margin-bottom:0}
.mb-5{margin-bottom:5px}
.mb-10{margin-bottom:10px}
.mb-15{margin-bottom:15px}
.mb-20{margin-bottom:20px}
.mb-25{margin-bottom:25px}
.mb-30{margin-bottom:30px}
.mb-35{margin-bottom:35px}
.mb-40{margin-bottom:40px}
.mb-50{margin-bottom:50px}
.mb-60{margin-bottom:60px}
.mb-80{margin-bottom:80px}

.mt-0{margin-top:0}
.mt-5{margin-top:5px}
.mt-10{margin-top:10px}
.mt-15{margin-top:15px}
.mt-20{margin-top:20px}
.mt-25{margin-top:25px}
.mt-30{margin-top:30px}
.mt-35{margin-top:35px}
.mt-40{margin-top:40px}
.mt-50{margin-top:50px}
.mt-60{margin-top:60px}
.mt-80{margin-top:80px}

.minHeight-5{min-height:5px}
.minHeight-10{min-height:10px}
.minHeight-15{min-height:15px}
.minHeight-20{min-height:20px}
.minHeight-25{min-height:25px}
.minHeight-30{min-height:30px}
.minHeight-35{min-height:35px}
.minHeight-40{min-height:40px}
.minHeight-50{min-height:50px}
.minHeight-60{min-height:60px}
.minHeight-80{min-height:80px}
.minHeight-90{min-height:90px}
.minHeight-100{min-height:100px}
.minHeight-120{min-height:120px}
.minHeight-140{min-height:140px}
.minHeight-160{min-height:160px}
.minHeight-180{min-height:180px}
.minHeight-200{min-height:200px}
.minHeight-250{min-height:250px}
.minHeight-300{min-height:300px}
.minHeight-350{min-height:350px}
.minHeight-400{min-height:400px}
.minHeight-450{min-height:450px}
.minHeight-500{min-height:500px}
