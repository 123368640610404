@charset "utf-8";

.LeftSection{
   width: 220px;
   background: #141922;
   float: left;
   min-height: 100vh;
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
}

#teamName {
   font-size: 18px;
   line-height: 25px;
   padding: 0 1rem;
   color: #fff;
   position: relative;
   z-index: 50;
   flex-basis: 61px;
   flex-shrink: 0;
   font-weight:900;
   white-space:nowrap;
   width:180px;
   padding: 10px 10px 10px 15px;
   transition: all 0s
}

#team_menu_user_name {
   color: #cac4c9;
   font-size: 14px;
   line-height: 14px;
   padding: 0 15px;
   position: relative;
   transition: all 0s;
   top: -10px;
}

#team_menu_user_name i {
   font-size:14px;
   margin-right:5px;
   color:#3a938b;
}


.Tgmenu{
   width: 26px;
   height: 30px;
   cursor: pointer;
   display: block;
   position: absolute;
   right: 20px;
   top: 10px;
}
.Tgmenu span:nth-child(1){
   width:40%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 7px;
}
.Tgmenu span:nth-child(2){
   width:100%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 15px;
}
.Tgmenu span:nth-child(3){
   width:60%;
   height: 1px;
   display: block;
   background-color: #fff;
   position: absolute;
   right: 0;
   top: 23px;
}
.bottomLogo{
   margin-top: 20px;
   text-align: center;
}
.bottomLogo img{
   max-width: 100%;
   height: auto;
}
.Tgmenu:hover span{
   width: 100%;
}
.leftMenu{
   padding: 15px 0;
   overflow: hidden;
}

.leftMenu ul{
   margin: 0;
   padding: 0;
   list-style-type: none;
}
.leftMenu ul li{
   margin: 0;
   padding: 0;
   list-style-type: none;
   width: 100%;
   height: 30px;
   font-size: 16px
}

.leftMenu ul li.sectionTitle{
   padding-left: 15px;
   list-style-type: none;
   width: 100%;
   height: 25px;
   color:#fff;
   font-size: 16px;
   text-transform: none!important;
   background: transparent!important;
   transition: all 0s;
   position: relative;
}

.leftMenu ul li.sectionTitle button {
  background:none;
  padding:0;
  margin:0;
  position: absolute;
  top:0;
  right:8px;
  color:#fff;
  opacity: .64;
  border: 2px solid #fff;
  width:18px;
  height:18px;
  border-radius: 9px;
  outline: none!important;
  box-shadow: none!important;
}

.leftMenu ul li.sectionTitle button:hover {
  opacity: .84;
}

.leftMenu ul li.sectionTitle button i {
  margin:0;
  padding:0;
  font-size:10px;
  width:100%;
  text-align:center;
  line-height:14px;
  height:14px;
  display: block;
}

.leftMenu ul:last-child li.sectionTitle{
  margin-top:20px
}

.leftMenu ul li a {
   padding: 0px 15px;
   height: 30px;
   line-height: 30px;
   display: table;
   width: 220px;
   overflow: hidden;
   text-decoration:none !important;
}

.leftMenu ul li a span{
   color:#7e8592;
   display:table-cell;
   line-height: 30px;
}

.leftMenu ul li a i{
   text-align: left;
   width: 20px;
   font-size: 12px;
   display:table-cell;
   min-height: 30px;
   vertical-align: middle;
   color:#7e8592;
   position: relative;
   transition: all ease-in-out 500ms;
}

.leftMenu ul li a:hover{
   background-color:#303084;
   text-decoration: none;
}

.leftMenu ul li a:hover span{
   color:#fff;
}

.leftMenu ul li:hover i {
   color:#fff;
}


/* .leftMenu ul li a.active span, .leftMenu ul li a.active i {
   color: #fff;
}

.leftMenu ul li a.active {
   background-color:#303084;
} */

.LeftCover{
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background: rgba(0,0,0,.1);
   z-index:1000;
   display: none;
}

.nav.navbar-nav li a {
   color:#cac4c9;
   font-size: 15px;
}

@media only screen and (max-width:991px) {

}
