.boxTile {
   width: 100%;
   background:#fff;
   border-radius: 5px;
   position: relative;
   cursor: pointer;
   padding:20px;
}
.boxTile.shadow{
    box-shadow: 0 0 4px rgba(0,0,0, .14);
}
.boxTile:hover {  
    box-shadow: 0 0 4px rgba(0,0,0, .14);
 }
.boxTile .iconCirle {
    width:42px;
    height:42px;
    border-radius: 21px;
    margin: 0 auto;
    display:block;
    position: relative;
    top:30px;
}
.boxTile .iconCirle i {
    width:100%;
    text-align: center;
    line-height:42px;
    font-size: 18px;
}

.boxTile .megaTitle {
    width:100%;
    text-align: center;
    font-size: 300%;
    margin-top:30px;
    color:#2d2f39;
    font-weight: 600;
}

.boxTile .subTitle {
    width:100%;
    text-align: center;
    font-size: 14px;
    color:#84888b;
}

.reports.boxTile {
    height:60px;
    min-height:60px;
}
.boxTile-extras{display: none}
.reports.boxTile.open {
    height: auto;
    min-height:150px;
    padding:20px;
    background: #eef9f0;
    box-shadow: 0 0 4px rgba(0,0,0, .24)
}
.reports.boxTile .subTitle {
    text-align: left;
    font-size: 18px;
    color: #333;
}
.reports.boxTile .shortDivider {
    width:5px;
    height: 5px;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    margin-top: 8px;
    transition: all ease-in-out 600ms;
}

.reports.boxTile.open .shortDivider {
    width:60px;
    background: #37c13a;
}

.reports.boxTile.open .boxTile-extras{display: block}

.datePickerPop {
    float: right;
    width: auto;
    height: 35px;
    line-height: 35px;
    color: #333;
    background: #fff;
    border-radius: 5px;
    padding: 0 35px 0 20px;
    position: relative;
    cursor: pointer;
    margin-top:20px;
}

.datePickerPop i {
    position: absolute;
    top:10px;
    right: 10px;
}

.exportBlock ul li{
    font-weight: 600
}
.exportBlock ul li:hover{
    color:#303084;
}