
#messagesContainer {
  width:100%;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  position:relative;
}
#messagesContainer .profilePicture {
  display: block;
  height: auto;
  float: left;
  width:36px;
  height: 36px;
  margin-right:8px;
  border-radius: 3px;
  background: #ececec
}
#messagesContainer .messageContainer {
  display: block;
  height: auto;
  float: left;
}

#messagesContainer ul {
  list-style-type: none;
  padding:0;
  margin:0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#messagesContainer ul li {
  padding:0;
  margin:0;
  margin-bottom:20px;
  width:100%
}

#messagesContainer ul li .messageContainer .userName {
  color:#2c2d30;
  font-size: 15px;
  font-weight:700;
  display:inline-block;
  padding-right:10px
}

#messagesContainer ul li .messageContainer .timeCreated {
  color: #717274;
  font-size: 12px;
  font-weight:400;
}

#messagesContainer ul li .messageContainer .message {
  color:#2c2d30;
  font-size: 15px;
  font-weight:400;
  line-height: 22px;
}

.threadUsers {
  border: 1px solid #b1b1b1;
  border-radius:4px;
  width:100%;
  min-height: 47px;
  margin-top:1px;
}

.threadUsers ul {
  list-style-type: none;
  margin:0
}
.threadUsers ul li {
  height: 35px;
  line-height: 35px;
  font-size:15px;
  cursor: pointer
}
