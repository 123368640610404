@charset "utf-8";

.modalBackground {
   background:#fff;
   position:relative;
   width:100%;
   height:calc(100vh - 40px);
   z-index:999;
   color: #2c2d30;
   overflow: scroll;
   padding-bottom:40px;

}

.modalBackground button.modalClose {
   width:60px;
   height:60px;
   position: fixed;
   top:50px;
   right:50px;
   background: url(../images/modal/close.png) center no-repeat;
   border-radius: 30px;
   border:none;
   transition: all ease-in-out 500ms;
   outline: none!important;
   box-shadow: none!important;

}
.modalBackground button.modalClose:hover {
   background: url(../images/modal/close.png) center no-repeat #e8e8e8;

}
.modalBackground .modalContent {
   width:640px;
   height:auto;
   position: relative;
   margin: 60px auto 0;
}

.modalBackground  h1 {
   font-size: 34px;
   font-weight: 900;
   height:34px;
   padding:0;
   margin:0;
}

.modalBackground .subHeading{
   color:#717274;
   font-size: 15px;
   font-weight: 300;
   margin:25px 0 55px;
}

.modalBackground .form-group {
   margin-bottom:20px;
   position: relative;
   min-height: 106px;
}

.modalBackground .btn-toolbar {
   padding-top:20px;
}

.modalBackground .form-group .overlayTitle {
  height:auto;
  min-height:106px;
  position:absolute;
  top: 0;
  left: -15px;
  right: -15px;
  border-radius:10px;
  overflow:hidden;
  background: repeating-linear-gradient(
    315deg,
    #ececec,
    #ececec 2px,
    #fff 2px,
    #fff 8px
  );
  z-index:99;
  cursor:pointer;
}

.modalBackground .form-group .overlayTitle .title {
  width:100%;
  position:absolute;
  top:38px;
  font-size: 18px;
  text-align: center;
  font-weight:600;
}
.modalBackground .form-group .overlayTitle .description {
  width:100%;
  position:absolute;
  top:66px;
  font-size: 16px;
  text-align: center;
  color: #696969;
  font-weight: 300;
}
.modalBackground .form-group .overlayTitle .step {
  width:30px;
  height:30px;
  line-height:30px;
  border-radius: 15px;
  left:50%;
  margin-left:-15px;
  background: #82d483;
  position:absolute;
  top:6px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.modalBackground label {
   font-size: 16px;
   font-weight:600;
}

.modalBackground .form-control {
   height:47px;
   outline: none!important;
   box-shadow: none;
   border: 1px solid #919193;
   font-size: 20px;
   color: #2c2d30;
   font-weight:400;
}

.modalBackground .form-group ::placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .form-group :-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .form-group ::-ms-input-placeholder { color: #999999;font-size:17px; font-weight:300; }
.modalBackground .helpText {
   font-size: 12px;
   height:12px;
   color:#717274;
   margin-top:8px;
   font-weight: 400;
}

.modalBackground .radioSelector {
   height:55px;
   width:100%;
   border: 1px solid #e8e8e8;
   background: #f9f9f9;
   border-radius: 5px;
   margin-bottom: 8px;
   overflow: hidden;
   position: relative;
   cursor: pointer;
}

.modalBackground .radioSelector.active, .modalBackground .radioSelector:hover {
   border: 1px solid #2d9ee0;
   background: #eaf5fc;
}

.modalBackground .radioSelector input[type=radio]{
   position: absolute;
   left:13px;
   top:13px;
}

.modalBackground .radioSelector label {
   margin: 0;
   width: 100%;
   position: relative;
   padding-left:36px;
   padding-top:8px;
   cursor: pointer;
   height: 55px;
}

.modalBackground .radioSelector label.noDescription {
   padding-top:15px;
}

.modalBackground .radioSelector label span {
   font-size: 16px;
   font-weight: 600;


}

.modalBackground .radioSelector .subLabel {
   font-size: 15px;
   color: #717274;
   font-weight: 300;
}

.modalBackground .conditionOperator {
   text-align: center;
   font-size:24px;
   font-weight:700;
}

.react-toggle-track {
  position:relative
}
.react-toggle-thumb {
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px!important;
  left: 2px!important;
  width: 23px;
  height: 23px;
  border: none!important;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none!important
}
.react-toggle-track {
    width: 64px!important;
    height: 26px!important;
    background-color: #f26130!important;
    transition: all 0.2s ease;
}
.react-toggle--checked .react-toggle-track {
    background-color: #008952!important;
}
.react-toggle--checked .react-toggle-thumb {
    left: 40px!important;
}
.react-toggle {
  margin-right:15px;
}
.toggle-group label span {
  font-size: 16px!important;
  line-height: 20px;
  margin: 0;
  padding: 0;
  height: 20px;
  position: relative;
  top: -6px;
  font-weight: 400
}
