.secondaryContent {
   position: fixed;
   width: 399px;
   height: calc(100vh - 120px);
   border-left: 1px solid #dfdfdf;
   float: left;
   top:120px;
   right:0;
   overflow: hidden;
   overflow-y: scroll;
}

.secondaryContent .heading {
   border-bottom: 1px solid #dfdfdf;
   background: #f9f9f9;
   padding: 19px 12px;
   position: absolute;
   width: 399px;
   height: 80px;
   top: 0;
   left: 0;
}

.secondaryContent .heading i {
   position: absolute;
   top:30px;
   right:20px;
   color:#6c6d6f;
   font-size:20px;
}

.secondaryContent .heading i:hover {
   cursor:pointer;
   color:#2d85b9;
}

.secondaryContent .heading .HeaderTitle {
  width: 330px;
  white-space: nowrap;
}
.secondaryContent .content {
   width:100%;
   height:auto;
   padding:15px;
   margin-top: 80px;
}

.secondaryContent .content .section {
   height: auto;
   padding-bottom: 7px;
   border-bottom: 1px solid #e8e8e8;
   margin-bottom: 20px
}

.secondaryContent .content .section h3 {
   padding:0;
   margin:0;
   font-size: 16px;
   line-height:16px;
   font-weight: 300;
   color:#333;
   margin-bottom:20px;
}

ul {
   list-style-type: none;
   padding-left:5px;
   font-size: 14px;
}
